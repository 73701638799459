import React, { Component } from 'react'

import "./bannerPage.scss"

class BannerPage extends Component {
    render() {
        return (
            <div className="space-3-bottom">
                <div className={`banner-page banner-page-${this.props.color}`}>
                    <div className="container space-2-bottom">
                        <div className="row justify-content-center">
                            <div className="col-12 col-lg-10 col-xl-8">
                                <h1 className="banner-page__title">{this.props.title}</h1>
                                <p className="banner-page__text">{this.props.text}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`banner-page__footer-${this.props.color}`}>
                    <div className="banner__wave" />
                </div>
            </div>
        )
    }
}


export default BannerPage