import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/common/Layout/layout"
import SEO from "../../components/common/SEO/seo"
import BannerPage from "../../components/banner/BannerPage/bannerPage"
import SectionTextImage from "../../components/section/TextImage/textImage"
import Pricing from "../../components/section/Pricing/pricing"
import CallToAction from "../../components/section/CallToAction/callToAction"

const RedesSocialesPage = () => {
    const data = useStaticQuery(graphql`
    query {
      socialMediaImage1: file(relativePath: { eq: "images/sections/marketing-digital/redes-sociales/que-es-estrategia-social-media-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      socialMediaImage2: file(relativePath: { eq: "images/sections/marketing-digital/redes-sociales/razones-invertir-redes-sociales-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      socialMediaImage3: file(relativePath: { eq: "images/sections/marketing-digital/redes-sociales/acciones-social-media-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return (
        <Layout>
            <SEO
                title="Redes Sociales"
                description="Agencia de Marketing Digital en Barcelona experta en redes sociales para empresas. Realizamos el Community manager, gestión de comunidades y Social Media Ads."
            />

            <BannerPage
                title="Agencia Redes Sociales"
                text="Expertos en gestión, management y promoción de redes sociales para empresas"
                color="green"
            />

            <SectionTextImage
                title1="¿Qué es un "
                title2="Social Media plan?"
                text={<Fragment>
                    Para poder estar presente en las redes sociales y tener una buena reputación de marca, se necesita de un <strong>plan de acciones, de una estrategia</strong>, es decir, de un seguido de tácticas y pericias que gestionadas de forma óptima y planificada, consigan el objetivo de una empresa; más clientes, más engagement, más conocimiento de marca, etc.<br />
                    ¿Tu empresa sigue un plan de acción y estrategia de contenidos cuando publica en redes sociales? ¿Tienes unos objetivos empresariales relacionados?
                </Fragment>}
                image={data.socialMediaImage1.childImageSharp.fluid}
                imageAlt="Agencia Redes Sociales - Agencia Marketing Digital"
                color="green"
                containerTextRight={false}
            />

            <SectionTextImage
                title1="10 razones para estar "
                title2="en redes sociales"
                text={<Fragment>
                    <ul class="text-left">
                        <li><strong>Canal idóneo </strong> para generar contenido de interés.</li>
                        <li>Medio de lo más <strong>creativo</strong> para lanzar acciones de branding.</li>
                        <li>Mejor forma de <strong>aumentar la visibilidad</strong> en la red.</li>
                        <li>Fórmula excelente para <strong>gestionar la reputación online</strong>.</li>
                        <li>Creación de <strong>experiencias</strong>.</li>
                        <li>Método más efectivo para <strong>solucionar problemas y dudas</strong>.</li>
                        <li>Vía publicitaria para <strong>promocionar servicios y productos</strong>.</li>
                        <li>Mecanismo de <strong>cercanía y escucha activa</strong>.</li>
                        <li>Recurso para fomentar la <strong>confianza y credibilidad</strong>.</li>
                        <li><strong>Factor digital</strong> crucial para estar donde los usuarios están.</li>
                    </ul>
                </Fragment>}
                image={data.socialMediaImage2.childImageSharp.fluid}
                imageAlt="Agencia Redes Sociales - Agencia Marketing Digital"
                color="green"
                containerTextRight={true}
            />

            <SectionTextImage
                title1="¿Qué acciones "
                title2="llevamos a cabo?"
                text={<Fragment>
                    <ul class="text-left">
                        <li>Plan estratégico y de contenido.</li>
                        <li>Gestión  &amp; Community Management.</li>
                        <li>Social Media Ads: Campañas de publicidad en las diferentes redes sociales (Facebook, Instagram, Linkedin, Twitter, etc.).</li>
                        <li>Analítica y monitorización de resultados.</li>
                    </ul>
                    <br />
                    En IBX Agency diseñamos tu <strong>estrategia en redes sociales</strong>. ¡Escuchamos, creamos, gestionamos, medimos y actuamos!
                </Fragment>} image={data.socialMediaImage3.childImageSharp.fluid}
                imageAlt="Agencia Redes Sociales - Agencia Marketing Digital"
                color="green"
                containerTextRight={false}
            />

            <hr className="space-2-bottom space-3-bottom--lg" />

            <Pricing
                color="green"
                silverText="Ideal para Startups y pymes"
                silverPrice="250"
                silverPriceCurrency="€/mes"
                silverFeatures={["Planning mensual de publicaciones", "3 publicaciones mensuales (post & stories)", "Gestión de las cuentas Instagram & Facebook", "Copy de texto a medida", "Creatividad personalizada", "Community Management", "Informe de resultados", "Gestión Social Ads (Promociones y concursos)"]}
                silverException={["* Pago de publicidad no incluido en el fee. En caso de hacerse cargo IBX Agency, se cobrará un recargo en función de la inversión."]}
                goldText="Ideal para negocios consolidados"
                goldPrice="350"
                goldPriceCurrency="€/mes"
                goldFeatures={["Planning mensual de publicaciones", "5 publicaciones mensuales (post & stories)", "Gestión de las cuentas Instagram & Facebook", "Copy de texto a medida", "Creatividad personalizada", "Community Management", "Informe de resultados", "Gestión Social Ads (Promociones y concursos)"]}
                goldException={["* Pago de publicidad no incluido en el fee. En caso de hacerse cargo IBX Agency, se cobrará un recargo en función de la inversión."]}
                diamondText="Ideal para grandes empresas"
                diamondPrice="450"
                diamondPriceCurrency="€/mes"
                diamondFeatures={["Planning mensual de publicaciones", "7 publicaciones mensuales (post & stories)", "Gestión de las cuentas Instagram & Facebook", "Copy de texto a medida", "Creatividad personalizada", "Community Management", "Informe de resultados", "Gestión Social Ads (Promociones y concursos)"]}
                diamondException={["* Pago de publicidad no incluido en el fee. En caso de hacerse cargo IBX Agency, se cobrará un recargo en función de la inversión."]}
            />

            <CallToAction
                color="green"
                title="¿Quieres que gestionemos las redes sociales de tu empresa?"
                text={<Fragment>
                    ¡Somos la <strong>agencia de redes sociales en Barcelona</strong> que estás buscando! <br />
                    ¿Creamos tu plan ideal?
                </Fragment>}
                button="¡Llámanos!"
                buttonUrl="/contacto"
            />

        </Layout>
    )

}
export default RedesSocialesPage
