import React, { Component } from "react"

import Button from "../../common/Button/button"

import "./pricing.scss"

class SectionPricing extends Component {
    render() {

        return (
            <section className="container space-2-bottom space-3-bottom--lg">
                <div className="row space-2-bottom space-3-bottom--lg">
                    <div className="col-12">
                        <div className={`section-service-title ` + this.props.color}>Pricing</div>
                        <div className="section-service-subtitle">Nuestros planes de precios</div>
                    </div>
                </div>
                <div className="row space-1-bottom space-2-bottom--lg">
                    <div className="col-12 col-md-4">
                        <div className="pricing">
                            <h2 className="pricing__title">Plan Silver</h2>
                            <p className="pricing__subtitle">{this.props.silverText}</p>
                            <div className="pricing__price">{this.props.silverPrice}<span>{this.props.silverPriceCurrency}</span></div>
                            <div className="pricing__price-txt">* IVA no incluido</div>
                            <div className="pricing__price-button">
                                <Button
                                    color={this.props.color}
                                    to="/contacto"
                                    text="Solicita ahora"
                                />
                            </div>
                            <ul className="pricing__price-features">
                                {this.props.silverFeatures.map((feature) =>
                                    <li className={`pricing__price-features-` + this.props.color}>{feature}</li>
                                )}
                            </ul>
                            {this.props.silverException.map((exception) =>
                                <div className="pricing__exception">
                                    {exception}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="pricing">
                            <h2 className="pricing__title">Plan Gold</h2>
                            <p className="pricing__subtitle">{this.props.goldText}</p>
                            <div className="pricing__price">{this.props.goldPrice}<span>{this.props.goldPriceCurrency}</span></div>
                            <div className="pricing__price-txt">* IVA no incluido</div>
                            <div className="pricing__price-button">
                                <Button
                                    color={this.props.color}
                                    to="/contacto"
                                    text="Solicita ahora"
                                />
                            </div>
                            <ul className="pricing__price-features">
                                {this.props.goldFeatures.map((feature) =>
                                    <li className={`pricing__price-features-` + this.props.color}>{feature}</li>
                                )}
                            </ul>
                            {this.props.goldException.map((exception) =>
                                <div className="pricing__exception">
                                    {exception}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="pricing">
                            <h2 className="pricing__title">Plan Diamond</h2>
                            <p className="pricing__subtitle">{this.props.diamondText}</p>
                            <div className="pricing__price">{this.props.diamondPrice}<span>{this.props.diamondPriceCurrency}</span></div>
                            <div className="pricing__price-txt">* IVA no incluido</div>
                            <div className="pricing__price-button">
                                <Button
                                    color={this.props.color}
                                    to="/contacto"
                                    text="Solicita ahora"
                                />
                            </div>
                            <ul className="pricing__price-features">
                                {this.props.diamondFeatures.map((feature) =>
                                    <li className={`pricing__price-features-` + this.props.color}>{feature}</li>
                                )}
                            </ul>
                            {this.props.diamondException.map((exception) =>
                                <div className="pricing__exception">
                                    {exception}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="pricing-footer">
                            <div className="row">
                                <div className="col-12 col-md-8">
                                    <div className={`pricing-footer__title ` + this.props.color}>
                                        ¿Necesitas un plan personalizado?
                                    </div>
                                    <div className="pricing-footer__text">
                                        Te adecuamos una propuesta según tus necesidades y presupuesto.
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="pricing-footer__button">
                                        <Button
                                            color={this.props.color}
                                            to="/contacto"
                                            text="Pide presupuesto"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default SectionPricing